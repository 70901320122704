import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Link } from '@mui/material';
import PageWrapper from '@/components/PageWrapper';
import { Heading, Paragraph, SectionTitle } from '@/components/Styled';
import nsTranslate from '@/utils/nsTranslate';

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  const { t } = nsTranslate('pages.privacyPolicy');
  const { t: sharedT } = nsTranslate('shared');
  const companyName = sharedT('.companyName');
  const contactUsRef = useRef<HTMLDivElement>(null);
  const scrollToContactUs = () => {
    contactUsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <PageWrapper>
      <Heading variant='h5'>{t('.title')}</Heading>
      <Paragraph fontWeight={900}>
        {t('.lastUpdated',
          {
            date: new Date(Date.UTC(2022, 9, 2)),
            formatParams: { date: { day: 'numeric', month: 'long', year: 'numeric' } }
          })
        }
      </Paragraph>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.paragraph1'>
          <Link href='#' onClick={() => navigate('/')}></Link>
          {{companyName}}
        </Trans>
      </Paragraph>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.paragraph2'>
          <Link href={'mailto:tamas.kamaras@endeavia.com'}/>
        </Trans>
      </Paragraph>
      <Paragraph fontWeight={900} padding='40px 0 0'>{t('.summary.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.intro')}</Paragraph>
      <Paragraph>{t('.summary.section1.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section1.content')}</Paragraph>
      <Paragraph>{t('.summary.section2.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section2.content')}</Paragraph>
      <Paragraph>{t('.summary.section3.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section3.content')}</Paragraph>
      <Paragraph>{t('.summary.section4.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section4.content')}</Paragraph>
      <Paragraph>{t('.summary.section5.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section5.content')}</Paragraph>
      <Paragraph>{t('.summary.section6.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section6.content')}</Paragraph>
      <Paragraph>{t('.summary.section7.title')}</Paragraph>
      <Paragraph padding='0'>{t('.summary.section7.content')}</Paragraph>
      <Paragraph>{t('.summary.section8.title')}</Paragraph>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.summary.section8.content'>
          <Link href='#' onClick={() => navigate('/')}></Link>
        </Trans>
      </Paragraph>
      <SectionTitle>{t('.section1.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section1.content')}</Paragraph>
      <SectionTitle>{t('.section2.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section2.content')}</Paragraph>
      <SectionTitle>{t('.section3.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section3.content')}</Paragraph>
      <SectionTitle>{t('.section4.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section4.content')}</Paragraph>
      <SectionTitle>{t('.section5.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section5.content')}</Paragraph>
      <SectionTitle>{t('.section6.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section6.content')}</Paragraph>
      <SectionTitle>{t('.section7.title')}</SectionTitle>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.section7.content'>
          <Link href={'mailto:tamas.kamaras@endeavia.com'}/>
        </Trans>
      </Paragraph>
      <SectionTitle>{t('.section8.title')}</SectionTitle>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.section8.content1'>
          <Link sx={{ cursor: 'pointer' }} onClick={scrollToContactUs}/>
        </Trans>
      </Paragraph>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.section8.content2'>
          <Link href='https://commission.europa.eu/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en'/>
          <Link href='https://www.edoeb.admin.ch/edoeb/de/home.html'/>
        </Trans>
      </Paragraph>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.section8.content3'>
          <Link sx={{ cursor: 'pointer' }} onClick={scrollToContactUs}/>
          <Link href={'mailto:tamas.kamaras@endeavia.com'}/>
        </Trans>
      </Paragraph>
      <SectionTitle>{t('.section9.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section9.content')}</Paragraph>
      <SectionTitle>{t('.section10.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section10.content')}</Paragraph>
      <SectionTitle>{t('.section11.title')}</SectionTitle>
      <Paragraph padding='0'>{t('.section11.content')}</Paragraph>
      <SectionTitle ref={contactUsRef} fontWeight={900}>{t('.section12.title')}</SectionTitle>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.section12.content1'>
          <Link href={'mailto:tamas.kamaras@endeavia.com'}/>
          <Link href={'tel:+36304474964'}/>
        </Trans>
      </Paragraph>
      <Paragraph padding='0'>{t('.section12.content2')}</Paragraph>
      <Paragraph padding='0'>{t('.section12.content3')}</Paragraph>
      <Paragraph padding='0'>{t('.section12.content4')}</Paragraph>
      <Paragraph padding='0'>{t('.section12.content5')}</Paragraph>
      <Paragraph padding='0'>{t('.section12.content6')}</Paragraph>
      <SectionTitle>{t('.section13.title')}</SectionTitle>
      <Paragraph padding='0'>
        <Trans i18nKey='pages.privacyPolicy.section13.content'>
          <Link href='#' onClick={() => navigate('/contact')}></Link>
        </Trans>
      </Paragraph>
    </PageWrapper>
  );
}

export default PrivacyPolicy;
