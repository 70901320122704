import React from 'react';
import PageWrapper from '@/components/PageWrapper';
import { Heading, Paragraph } from '@/components/Styled';
import nsTranslate from '@/utils/nsTranslate';

const Main: React.FC = () => {
  const { t } = nsTranslate('pages.main');

  return (
    <PageWrapper>
      <Heading variant='h4'>{t('.heading1')}</Heading>
      <Heading variant='h6'>{t('.heading1_2')}</Heading>
      <Paragraph padding='40px 0'>{t('.paragraph1')}</Paragraph>
      <Heading variant='h5'>{t('.heading2')}</Heading>
      <Paragraph padding='40px 0'>{t('.paragraph2')}</Paragraph>
      <Heading variant='h5'>{t('.heading3')}</Heading>
      <Paragraph padding='40px 0'>{t('.paragraph3')}</Paragraph>
    </PageWrapper>
  );
}

export default Main;
