import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, SwipeableDrawer, Link, Stack, Button } from '@mui/material';
import { Global } from '@emotion/react';
import { Trans } from 'react-i18next';
import nsTranslate from '@/utils/nsTranslate';
import { Heading, Paragraph } from '@/components/Styled';

const CookieDrawer: React.FC = () => {
  const { t } = nsTranslate('components.cookieDrawer');
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(localStorage.getItem('cookieConsent') !== 'true' && sessionStorage.getItem('cookieConsent') !== 'true');
  const toggleDrawer = (newOpen: boolean) => (event: React.MouseEvent) => {
    if (event.type === 'click') { return; } // Prevents the drawer from closing when clicking randomly
    setOpen(newOpen);
  };
  const setConsent = (consent: boolean) => { localStorage.setItem('cookieConsent', consent.toString()); }
  const accecpt = () => {
    setConsent(true);
    setOpen(false);
  }
  const decline = () => {
    sessionStorage.setItem('cookieConsent', 'true');
    setConsent(false);
    setOpen(false);
  }

  return (
    <Box>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            margin: 'auto',
            minHeight: '200px',
            maxWidth: '600px',
            fontFamily: 'DejaVu Sans Light'
          }
        }}
        />
      <SwipeableDrawer
        anchor='bottom'
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
      >
        <Box p='20px'>
          <Heading variant='h5'>{t('.title')}</Heading>
          <Paragraph>
            <Trans i18nKey='components.cookieDrawer.content'>
              <Link href='#' onClick={() => navigate('/privacy_policy')}></Link>
            </Trans>
          </Paragraph>
          <Stack spacing={10} direction='row' justifyContent='center' p='20px 0 0'>
            <Button variant='outlined' onClick={decline}>{t('.buttons.decline.label')}</Button>
            <Button variant='contained' onClick={accecpt}>{t('.buttons.accept.label')}</Button>
          </Stack>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default CookieDrawer;
