import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Link } from '@mui/material';
import nsTranslate from '@/utils/nsTranslate';

const Footer: React.FC = () => {
  const { t } = nsTranslate('components.footer');
  const FooterLink: React.FC<{ children: ReactNode, path: string }> = ({ children, path }) => {
    const navigate = useNavigate();

    return (
      <Link component='button' variant='body1' underline='none' color='var(--light-gray)' onClick={() => navigate(path)}>
        {children}
      </Link>
    );
  }

  return (
    <Box
      width='100%'
      minHeight='75px'
      textAlign='center'
      bgcolor='gray'
      color='white'
      pt='25px'
    >
      <Stack direction='row' justifyContent='center'>{t('.allRights')}</Stack>
      <Stack spacing={2} direction='row' justifyContent='center' pt='10px'>
        <FooterLink path='/privacy_policy'>{t('.privacy')}</FooterLink>
        <FooterLink path='/contact'>{t('.contact')}</FooterLink>
      </Stack>
    </Box>
  );
}

export default Footer;
