import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Main from './pages/Main';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy_policy' element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
