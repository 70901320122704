import React, { ReactNode, useEffect } from 'react';
import { Grid, Box, Fade } from '@mui/material';
import Logo from './Logo';
import Footer from './Footer';
import CookieDrawer from './CookieDrawer';

const PageWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Fade in={true}>
      <Grid
        container
        justifyContent='center'
        bgcolor='var(--light-gray)'
        fontFamily='DejaVu Sans Light, sans-serif'
      >
        <Grid item xs={12} sm={10} md={8} lg={6} bgcolor='white'>
          <Logo />
          <Box minHeight='calc(100vh - 270px)' p='40px'>{children}</Box>
          <Footer />
        </Grid>
        <CookieDrawer />
      </Grid>
    </Fade>
  );
}

export default PageWrapper;
