import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './locales/en.json';

i18n
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: english
      },
    },
    fallbackLng: 'en',
    debug: process.env.REACT_APP_DEBUG === 'true',
  });

export default i18n;
