import { useTranslation } from 'react-i18next';

const nsTranslate = (namespace?: string) => {
  const { t } = useTranslation();
  const translate = (key: string, interpolation?: object) => {
    const i18nKey = namespace ? `${namespace}${key}` : key;
    return t(i18nKey, { ...interpolation })
  }
  return { t: translate };
}

export default nsTranslate;
