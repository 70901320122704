import { Typography, Box } from '@mui/material';
import styled from '@mui/system/styled';

interface ParagraphProps {
  padding?: string;
}

export const Heading = styled(Typography)(() => ({
  textAlign: 'center',
  fontFamily: 'DejaVu Sans Light'
}));

export const Paragraph = styled(Box)<ParagraphProps>(({ padding }) => ({
  padding: padding || '15px 0 0',
  textAlign: 'justify'
}));

export const SectionTitle = styled(Box)(() => ({
  padding: '20px 0 0',
  textAlign: 'justify',
  fontWeight: 900
}));
