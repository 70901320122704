import React from 'react';
import { Box, Link } from '@mui/material';
import PageWrapper from '@/components/PageWrapper';
import { Heading } from '@/components/Styled';
import Map from './Map';
import nsTranslate from '@/utils/nsTranslate';

const Contact: React.FC = () => {
  const { t } = nsTranslate('pages.contact');

  return (
    <PageWrapper>
      <Heading variant='h6'>{t('.title')}</Heading>
      <Box textAlign='center' p='20px'>{t('.paragraph1')}</Box>
      <Box textAlign='center' p='20px' fontWeight={900}>{t('.companyName')}</Box>
      <Box textAlign='center' p='10px'>{t('.address')}</Box>
      <Box textAlign='center' p='2px'>{t('.ceoName')}</Box>
      <Box textAlign='center' p='2px'>{t('.ceoTitle')}</Box>
      <Box textAlign='center' p='2px'>
        <Link href={`tel:${t('.phone')}`}>{t('.phone')}</Link>
      </Box>
      <Box textAlign='center' p='2px'>
        <Link href={`mailto:${t('.email')}`}>{t('.email')}</Link>
      </Box>
      <Map />
    </PageWrapper>
  );
}

export default Contact;
