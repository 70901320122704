import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';

const customIcon = new L.Icon({ iconUrl: icon });

const Map: React.FC = () => {
  const position: [number, number] = [47.54782268077815, 19.066688078478585];

  return (
    <MapContainer center={position} zoom={16} style={{ height: '400px', marginTop: '40px' }}>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon} />
    </MapContainer>
  );
};

export default Map;
