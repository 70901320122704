import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import logo from './endeavia_logo_transparent.png';

const Logo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      maxWidth='200px'
      height='50px'
      margin='auto'
      py='20px'
      sx={{ cursor: 'pointer'}}
      onClick={() => navigate('/')}
    >
      <img src={logo} alt='logo' style={{ maxWidth: '100%' }} />
    </Box>
  );
}

export default Logo;
